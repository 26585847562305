import { Box } from '@mui/material';
import './App.css';
import About from './components/about';
import Navbar from './components/navbar';
import Hero from './components/heroPage';
import Expect from './components/expect';
import Compete from './components/compete';
import Prices from './components/prices';
import Mentor from './components/mentor';
import Jury from './components/jury';
import Schedule from './components/schedule';
import Footer from './components/footer';
import Timeline from './components/timeline';

function App() {
  return (
    <Box>
      <Navbar />
      <Hero />
      <Compete />
      <Expect />
      <Prices />
      <Schedule />
      <Mentor />
      <Timeline />     
      {/* <Jury /> */}
      <About />
      <Footer />
    </Box>
  );
}

export default App;
