import React from 'react'
import { Box, Container, Grid, Typography, Button, Link } from '@mui/material'
import scheduleqr from '../assets/pitchcombat.png'
import "../styles/component/schedule.css"
// import Image from 'next/image'
// import step1 from "../assets/schedulestep1.png"
// import step2 from "../assets/schedulestep2.png"
// import Link from 'next/link'
const Schedule = () => {
    return (
        <Box className='schedule-section' id='schedule'>
            <Container className='container schedule-container'>

                {/* <Box className='schedule-heading'>
                    <Typography>
                        Register now for workshop and mentorship support
                    </Typography>
                    <span className="heading-line primary"></span>
                </Box> */}

                <Grid container className='schedule-grid-container' rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }} sx={{display:"flex",justifyContent:"center"}}>
                    <Grid item xs={12} md={6} sm={12} className='' sx={{justifyContent:"center"}}>
                        <Box className='schedule-details'>
                            <Typography sx={{ marginTop: "30px" }}>
                                Register now for <span>workshop</span> and <span>mentorship</span> support
                            </Typography>
                            <Typography>
                                This Pitch tournament is your chance to shine on the grand stage.
                            </Typography>
                        </Box>

                        <Box className="steps">

                            {/* <img src={step1} alt="step1" />
                            <img src={step2} alt="step2" /> */}

                            {/* <Typography sx={{ marginTop: "30px" }}>
                                Register now for <span>workshop</span> and <span>mentorship</span> support
                            </Typography> */}
                            <Typography sx={{  }}>
                                For more  details download <Link href='https://drive.google.com/file/d/1dtur5vluE5H9kpV27GoB9YaADBMXYEqT/view?usp=sharing'>brochure</Link> here
                            </Typography>
                            {/* <Typography sx={{ marginTop: "20px" }}>
                                Look no further, Apply Now!
                            </Typography> */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} className='schedule-grid-item'>
                        <Box className='apply-content-box'>
                            <Box className="qr">
                                <img src={scheduleqr} alt="QR" />
                            </Box>
                            
                            <Box className='schedule-register-btn'>
                                <Typography sx={{ marginTop: "0px" }}>
                                    Look no further
                                </Typography>
                                <Button>
                                    <Link href="http://bit.ly/pitchcombat">
                                        Apply Now
                                    </Link>
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Schedule
