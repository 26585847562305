import * as React from 'react';
import "../styles/component/navbar.css"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';
import logo from "../assets/new_logo.png"
// import Image from 'next/image';


const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: "#171C40", boxShadow: "none" }}>
            <Container className='appbar-container' sx={{ maxWidth: "1440px" }}>
                <Toolbar disableGutters>

                    {/* main logo */}

                    <Box className='main-logo' sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Link href='#'>
                            <img src={logo} alt="logo" />
                        </Link>
                    </Box>


                    {/* menu items */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center"></Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>


                    {/* navbar items */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "center" }}>
                        <Box className='navbar-item'>
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <Link href='#about'>
                                    About Us
                                </Link>
                            </Button>
                        </Box>
                        <Box className='navbar-item'>
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <Link href='#mentor'>
                                    Mentors
                                </Link>
                            </Button>
                        </Box>
                        <Box className='navbar-item'>
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <Link href='#mentor'>
                                    Investors
                                </Link>
                            </Button>
                        </Box>
                        <Box className='navbar-item'>
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <Link href='#schedule'>
                                    Timeline
                                </Link>
                            </Button>
                        </Box>
                        <Box className='navbar-item'>
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <Link href='#contact'>
                                    Contact
                                </Link>

                            </Button>
                        </Box>
                    </Box>


                    <Box sx={{ flexGrow: 0 }}>
                        <Box className='register-btn'>
                            <Button>
                                <Link href="http://bit.ly/pitchcombat">
                                    Register Now
                                </Link>
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navbar;