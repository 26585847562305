import { Box, Container, Grid, Typography, Link } from '@mui/material'
import "../styles/component/mentor.css"
import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import mentorData from "../data/mentor.json"
import mentoricon from "../assets/mentor.webp"


const Mentor = () => {
    return (
        <Box className='section mentor-section' id='mentor'>
            <Container className='mentor-container'>
                <Box className="mentor-heading">
                    <Typography variant='h2'>
                        Investors and Mentors
                    </Typography>
                    <span className="heading-line primary"></span>
                </Box>


                <Box className='mentor-icon'>
                    <img src={mentoricon} alt="jury" />
                </Box>

                <Box className='mentor-register'>
                    <Typography>
                        <Link href='https://forms.office.com/r/xiz6PZCxNe'>
                            Nominate <span>Investor</span> | <span>Mentor</span>
                        </Link>

                    </Typography>
                </Box>

                {/* <Box className='profile-card'>
                    <Grid container className='mentor-grid-container' rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                        {
                            mentorData.map((data,index) => (
                                <Grid key={index} item xs={12} md={3} sm={12} className='mentor-grid-item'>

                                    <Card sx={{ boxShadow: "none", borderRadius: "12px",background:'transparent' }} className='card'>
                                        <CardMedia
                                        className='card-media'
                                            sx={{ height: 260 }}
                                            image={data.img}
                                            title="green iguana"

                                        />
                                        <CardContent className='card-content'>
                                            <Typography variant="h3">
                                                {data.name}
                                            </Typography>
                                            <Typography variant="h5">
                                                {data.designation}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>

                </Box> */}

            </Container>
        </Box>
    )
}

export default Mentor
