import { Box, Container, Grid, Typography } from '@mui/material'
import "../styles/component/compete.css"
import React from 'react'

const Compete = () => {
    return (
        <Box className='compete-section'>
            <Container className='compete-container'>
                <Box className='compete-content'>
                    <Box className='compete-content-heading'>
                        <Typography>
                            Compete With The Best!
                        </Typography>
                        <span className="heading-line primary"></span>
                    </Box>

                    <Box className='compete-content-para'>
                        <Typography>
                            JAIN Launchpad is bringing two thrilling pitch competitions to the forefront. Sixteen carefully selected startups from local colleges, universities, and startup ecosystems will go head-to-head in the Founders Fight Club and Lightweight Collegiate Pitch competition. The winners will not only receive valuable prizes but also earn the prestigious titles of Lightweight and Founder Fight Club Champion.
                        </Typography>
                    </Box>

                    <Grid container className='' rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginTop: '' }}>
                        <Grid item xs={12} md={6} sm={12} className=''>
                            <Box className='book-trip-box1'>
                                <Box className='book-trip-heading'>
                                    <Typography>
                                        Founders Fight Club:
                                    </Typography>
                                    <span className="heading-line primary"></span>
                                </Box>
                                <Box className='book-trip-list'>
                                    <Typography>
                                        Is your startup ready to face the heat? To qualify for the Founders Fight Club, your startup must meet the following requirements:
                                    </Typography>

                                </Box>
                                <Box className='book-trip-eligibility-heading'>
                                    <Typography>
                                        Eligibility Criteria:
                                    </Typography>
                                </Box>
                                <Box className='book-trip-eligibility-list'>
                                    <ul>
                                        <li>
                                            Company incorporated as a Private Limited, LLP, Registered Partnership.
                                        </li>
                                        <li>
                                            Must have either an MVP or product ready.
                                        </li>
                                        <li>
                                            Must have early revenue/pilot customers. Must have at least one full-time founder.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} className=''>
                            <Box className='book-trip-box2'>
                                <Box className='book-trip-heading'>
                                    <Typography>
                                        Lightweight Collegiate Pitch:
                                    </Typography>
                                    <span className="heading-line primary"></span>
                                </Box>
                                <Box className='book-trip-list'>
                                    <Typography>
                                        Are you a student entrepreneur with a brilliant startup idea? Here is your chance to shine!
                                    </Typography>

                                </Box>
                                <Box className='book-trip-eligibility-heading'>
                                    <Typography>
                                        Eligibility Criteria:
                                    </Typography>
                                </Box>
                                <Box className='book-trip-eligibility-list'>
                                    <ul>
                                        <li>
                                            Must be enrolled as a student at a college or university.
                                        </li>
                                        <li>
                                            Must be an active participant in an E-cell, Incubator or Accelerator program.
                                        </li>
                                        <li>
                                            Must participate with a team of five members.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>




                </Box>
            </Container>
        </Box>
    )
}

export default Compete
