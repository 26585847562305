import React from 'react'
import "../styles/component/prices.css"
import { Box, Container, Grid, Typography } from '@mui/material'
import prizes from "../assets/prices.png"

const Prices = () => {
    return (
        <Box className='prices-section'>
            <Container className='prices-container'>
                <Box className='prices-heading'>
                    <Typography>
                        Prizes and Recognition:
                    </Typography>
                    <span className="heading-line primary"></span>
                </Box>
                <Box className='prices-content'>
                    <img src={prizes} alt="prizes" />
                </Box>
            </Container>
        </Box>
    )
}

export default Prices
